
import { AxiosError } from "axios";
import { defineComponent, reactive, ref } from "vue";
import { useRouter } from "vue-router";

import authService from "@/services/Auth";

import TemplatesLoginAdmin from "@/components/templates/login-admin/index.vue";

import { IFormData } from "./interface";

export default defineComponent({
  components: { TemplatesLoginAdmin },
  setup() {
    const config = ref({
      isLoading: false,
      error: {},
    });
    const router = useRouter();

    const formData = reactive<IFormData>({
      email: "",
      password: "",
    });

    const submitLogin = async (formData: IFormData): Promise<void> => {
      config.value.isLoading = true;

      try {
        await authService.loginAdmin(formData);
        router.push("/admin/home");
      } catch (e: unknown) {
        const { response } = e as AxiosError;

        if (Object.keys(response?.data?.errors).length > 0) {
          config.value.error = response?.data?.errors;
        } else {
          alert(response?.data?.message);
        }
      }

      config.value.isLoading = false;
    };

    return {
      config,
      formData,
      submitLogin,
    };
  },
});


import { defineComponent, ref, toRefs, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";

import { IError } from "./interface";

export default defineComponent({
  props: {
    formData: {
      type: Object,
      default: () => ({
        email: "",
        password: "",
      }),
    },
    initialError: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const modalError = ref<boolean>(false);
    const error = ref<IError>({
      email: [],
      password: [],
    });
    const { formData, initialError } = toRefs(props);

    const rules = {
      email: {
        required: helpers.withMessage("Email harus diisi", required),
        email: helpers.withMessage("Email tidak valid", email),
      },
      password: {
        required: helpers.withMessage("Kata sandi harus diisi", required),
      },
    };
    const v$ = useVuelidate(rules, formData);

    const showPassword = ref(false);
    const toggleShowPassword = () => {
      showPassword.value = !showPassword.value;
    };

    const submitForm = (): void => {
      v$.value.$validate();
      const isInvalid = v$.value.$invalid;
      if (!isInvalid) emit("submit", formData.value);
    };

    const assignError = (paramsError: IError) => {
      if (Object.keys(paramsError).length > 0) {
        error.value = {
          ...paramsError,
        };
      }
    };

    watch(initialError, (value) => {
      assignError(value);
    });

    return {
      v$,
      modalError,
      error,
      submitForm,
      showPassword,
      toggleShowPassword,
    };
  },
});


import { defineComponent } from "vue";

import FormLoginAdmin from "@/components/organisms/form/login-admin/index.vue";

export default defineComponent({
  components: { FormLoginAdmin },
  props: {
    formData: {
      type: Object,
      default: () => ({
        username: "",
        password: "",
      }),
    },
    error: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["submit"],
});
